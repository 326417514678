// Swiper carousel
// -----------------------------------------------------------------------------
//
// 1. Fallback colour, in case no image is retrieved.
// 2. Data uri of 'keyboard-arrow-up-24-px.svg'.
// 3. Use inline styles instead, for dynamic images coming from CMS.
// 4. All gradient percentages must be specified (also 0%), and ordered in
//    ascending progression in order to work properly.
//

@import '../_variables';

// Colors
$_c-light: #fff;
$_c-dark-high-emphasis: rgba(0, 0, 0, 0.87);
$_c-dark-disabled-emphasis: rgba(0, 0, 0, 0.38);
$_c-light-disabled-emphasis: rgba(255, 255, 255, 0.38);
$_c-dark-gray: #545850;
$_c-primary: #012169;
$_c-secondary: #00a7e4;
// $body-c: $_c-dark-high-emphasis;

// Scaffolding
$_assets-path: '/assets/';
$_img-path: $_assets-path + 'img/';

// Media queries

$_screen-sm-min: 768px;
$_screen-md-min: 1024px;
$_screen-lg-min: 1280px;
$_screen-xl-min: 1440px;

$_small-down: 'max-width:#{$_screen-sm-min}';
$_medium-up: 'min-width:#{$_screen-md-min}';
$_large-up: 'min-width:#{$_screen-lg-min}';
$_xlarge-up: 'min-width:#{$_screen-xl-min}';

// Fonts
$_ff-primary: 'Open Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
$_ff-secondary: 'Colour Sans Bold', 'Open Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
$_ff-base: $_ff-primary;

// Typography: heading, subtitle, body, caption and overline
$_h3-ff: $_ff-secondary;
$_h3-fz: 3rem;
$_h3-fw: 400;
$_h3-lh: 1.167;
$_h3-ls: 0em;
$_h3-tt: none;
$_h3-c: $_c-dark-gray;

$_h4-ff: $_ff-secondary;
$_h4-fz: 2.125rem;
$_h4-fw: 400;
$_h4-lh: 1.235;
$_h4-ls: 0.00735em;
$_h4-tt: none;
$_h4-c: $_c-dark-gray;

$_subtitle-1-ff: $_ff-base;
$_subtitle-1-fz: 15px;
$_subtitle-1-fw: 600;
$_subtitle-1-lh: 1.6;
$_subtitle-1-ls: 0.15px;
$_subtitle-1-tt: none;
$_subtitle-1-c: $_c-dark-gray;

$_subtitle-2-ff: $_ff-base;
$_subtitle-2-fz: 13px;
$_subtitle-2-fw: 600;
$_subtitle-2-lh: 1.84615385;
$_subtitle-2-ls: 0.1px;
$_subtitle-2-tt: none;
$_subtitle-2-c: $_c-dark-gray;

$_overline-ff: $_ff-base;
$_overline-fz: 0.75rem;
$_overline-fw: 400;
$_overline-lh: 2.66;
$_overline-ls: 0.08333em;
$_overline-tt: uppercase;
$_overline-c: $_c-dark-gray;

// Carousel specifics
$_carousel-ht: 80px;
$_carousel-ht-xs: 120px;
$_carousel-ht-medium: 132px;
$_carousel-ht-large: 212px;
$_carousel-ht-xlarge: 300px;

$_carousel-brr: 4px;

// Mixins
@mixin _size($wd, $ht) {
  width: $wd;
  height: $ht;
}

@mixin _typography($ff, $fz, $fw, $lh, $ls, $tt) {
  font-family: $ff;
  font-size: $fz;
  font-weight: $fw;
  line-height: $lh;
  letter-spacing: $ls;
  text-transform: $tt;
}

.swiper {
  border-radius: 4px;
  margin: 32px 0 43px 0;
  height: $_carousel-ht;
  transition: height 0.25s ease-in-out;

  @media ($_small-down) {
    margin: 16px 0 32px 0;
  }
  @media ($_medium-up) {
    height: $_carousel-ht-medium;
  }

  @media ($_large-up) {
    height: $_carousel-ht-large;
  }

  @media ($_xlarge-up) {
    height: $_carousel-ht-xlarge;
  }
}

//
// Slide
//
.swiper-slide {
  position: relative;
  width: 100%;
  height: inherit;
  // 1
  background-image: linear-gradient(to right, #e0e1e3 0%, #dcddde 50%, #d3d0cb 75%, #c3c3bf 100%);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;

  @media (max-width: 320px) {
    background-size: 126%;
  }

  // Is link
  &.is-link:hover {
    cursor: pointer;
  }

  &--1 {
    background-image: url($_img-path + 'carousel/slide-01_updated.jpg'); // 1
  }

  &--2 {
    background-image: url($_img-path + 'carousel/slide-02_updated.jpg'); // 2
  }

  &--3 {
    background-image: url($_img-path + 'carousel/slide-03_new.jpg'); // 3
  }
}

//
// Content
//
.swiper-slide-content {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  width: 70%;
  user-select: none;

  @media ($_medium-up) {
    width: 63%;
  }

  @media ($_large-up) {
    left: 96px;
    width: 57%;
  }

  @media ($_xlarge-up) {
    width: 50%;
  }

  &__media {
    margin-right: 8px;

    @media ($_medium-up) {
      margin-right: 12px;
    }

    @media ($_large-up) {
      margin-right: 16px;
    }

    @media ($_xlarge-up) {
      margin-right: 20px;
    }
  }

  &__thumb {
    @include _size(48px, 48px);
    transition: width 0.25s ease-in-out, height 0.25s ease-in-out;

    @media ($_medium-up) {
      @include _size(84px, 84px);
    }

    @media ($_large-up) {
      @include _size(120px, 120px);
    }

    @media ($_xlarge-up) {
      @include _size(160px, 160px);
    }
  }

  // &__content {
  //   //
  // }

  &__overline {
    @include _typography($_overline-ff, $_overline-fz, $_overline-fw, $_overline-lh, $_overline-ls, $_overline-tt);
    color: $_overline-c;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__heading {
    @include _typography($_subtitle-2-ff, $_subtitle-2-fz, $_subtitle-2-fw, $_subtitle-2-lh, $_subtitle-2-ls, $_subtitle-2-tt);
    color: $_subtitle-2-c;
    margin-top: 0;
    margin-bottom: 0;

    @media ($_medium-up) {
      @include _typography($_subtitle-1-ff, $_subtitle-1-fz, $_subtitle-1-fw, $_subtitle-1-lh, $_subtitle-1-ls, $_subtitle-1-tt);
    }

    @media ($_large-up) {
      @include _typography($_h3-ff, $_h3-fz, $_h3-fw, $_h3-lh, $_h3-ls, $_h3-tt);
    }

    @media ($_xlarge-up) {
      @include _typography($_h3-ff, $_h3-fz, $_h3-fw, $_h3-lh, $_h3-ls, $_h3-tt);
    }
  }

  &__text {
    @include _typography($_ff-base, 13px, 600, 1.84615385, 0.1px, none);
    color: $_c-primary;
    margin-top: 0;
    margin-bottom: 0;

    @media ($_medium-up) {
      @include _typography($_ff-base, 15px, 600, 1.6, 0.15px, none);
    }

    @media ($_large-up) {
      @include _typography($_ff-secondary, 36px, 400, 1.22222222, 0.25px, none);
    }

    @media ($_xlarge-up) {
      @include _typography($_ff-secondary, 40px, 400, 1.25, 0.19px, none);
    }

    strong {
      font-family: $_ff-base;
      font-weight: 600;

      @media ($_large-up) {
        font-family: $_ff-secondary;
      }
    }

    span {
      color: $_c-secondary;
    }
  }
}

.swiper-slide--text-only .swiper-slide-content {
  @media ($_xlarge-up) {
    width: 50%;
  }
}

//
// Navigation (arrows)
//

.swiper-button-next::after,
.swiper-button-prev::after {
  content: '';
}

.swiper-button-prev,
.swiper-button-next {
  display: none;

  @media ($_large-up) {
    display: block;
    width: 48px;
    height: 48px;
    margin-top: 0;
    border-radius: 50%;
    background-size: 32px;
    background-color: rgba(255, 255, 255, 0.54);
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
    outline: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    }

    &:focus {
      outline: none;
    }
  }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cpath id='prefix__a' d='M1.77 5.5l3.234-3.233L8.238 5.5c.325.325.85.325 1.175 0 .325-.325.325-.85 0-1.175L5.587.5C5.432.344 5.22.256 5 .256c-.22 0-.432.088-.588.244L.587 4.325c-.324.325-.324.85 0 1.175.326.317.859.325 1.184 0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(5 7)'%3E%3Cmask id='prefix__b' fill='%23fff'%3E%3Cuse xlink:href='%23prefix__a'/%3E%3C/mask%3E%3Cg mask='url(%23prefix__b)'%3E%3Cpath fill='%23545858' d='M0 0H20V20H0z' transform='translate(-5 -7)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"); // 2
  transform: translateY(-50%) rotate(-90deg);
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cpath id='prefix__a' d='M1.77 5.5l3.234-3.233L8.238 5.5c.325.325.85.325 1.175 0 .325-.325.325-.85 0-1.175L5.587.5C5.432.344 5.22.256 5 .256c-.22 0-.432.088-.588.244L.587 4.325c-.324.325-.324.85 0 1.175.326.317.859.325 1.184 0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(5 7)'%3E%3Cmask id='prefix__b' fill='%23fff'%3E%3Cuse xlink:href='%23prefix__a'/%3E%3C/mask%3E%3Cg mask='url(%23prefix__b)'%3E%3Cpath fill='%23545858' d='M0 0H20V20H0z' transform='translate(-5 -7)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"); // 2
  transform: translateY(-50%) rotate(90deg);
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none;
}

.swiper-button-prev {
  @media ($_large-up) {
    left: 16px;
  }
}

.swiper-button-next {
  @media ($_large-up) {
    right: 16px;
  }
}

//
// Pagination (dot buttons)
//
.swiper-pagination-bullet {
  background: $_c-light;
  opacity: 0.38;
}

.swiper-pagination-bullet-active {
  background: $_c-light;
  opacity: 1;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -6px;
  @media ($_small-down) {
    display: none;
  }
  @media ($_medium-up) {
    bottom: -2px;
  }

  @media ($_large-up) {
    bottom: 2px;
  }

  @media ($_xlarge-up) {
    bottom: 6px;
  }
}

//
// IE 11
// 1. Remove carousel vertical scrollbar.
//
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  // 1
  .swiper-slide {
    overflow-y: hidden !important;
  }
}
